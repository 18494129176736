import React from "react"
import SEO from "../components/seo"
import FormAudit from "../components/formAudit/formAudit"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Reviews from "../components/reviews/reviews"
import Triangle from "../components/triangle/triangle"
import FeaturedCaseStudy from "../components/featuredCaseStudy/featuredCaseStudy"
const slugify = require("slugify")

const Career = ({ data: { page, shared }, location }) => {
  return (
    <>
      <SEO
        titleOverride={page.metaTags && page.metaTags.title ? page.metaTags.title : page.title}
        descriptionOverride={page.metaTags && page.metaTags.description ? page.metaTags.description : null}
        pathnameOverride={location.pathname}
        imageOverride={page.metaTags && page.metaTags.image ? page.metaTags.image.url : null}
      />

      <section className="pt-6 pb-16 overflow-hidden bg-grey-light md:pb-20 lg:pb-48">
        <div className="container">
          <div className="flex flex-wrap items-center md:-mx-8">
            <div className="flex justify-center w-full md:w-full md:px-8">
              <div>
                <h1 className="text-center">{page.heroHeading}</h1>
                <div className="text-center content content--reset hero-blurb-small" dangerouslySetInnerHTML={{ __html: page.heroBlurb }}></div>

                <div className="flex flex-wrap justify-center">
                  <a href="#audit" className="mb-4 mr-4 md:mb-0 btn btn--small btn--primary">Get a FREE Website Audit</a>
                  <Link to="/contact" className="btn btn--small btn--outline">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="my-5 md:-mt-0 lg:-mt-24">
        <div className="container flex flex-wrap items-center justify-center py-4 md:md:items-stretch">
          <div className="flex flex-wrap justify-center gap-12 md:w-full md:flex-no-wrap md:justify-between md:-mx-2">
            <div className="flex flex-wrap items-center justify-start w-full p-6 bg-white border-2 outline-none md:pr-12 md:w-4/6 border-grey ">
              <div className="w-full md:flex-1 md:px-2 max-w-s">
                <span className="block mb-2 text-base text-center h3 md:text-left md:text-xl md:mb-0">Top 3 UK Digital Agency, as voted by our clients.</span>
              </div>
              <div className="w-full md:w-auto md:px-2">
                <div className="w-64 mx-auto text-center md:w-64">
                <Img fluid={shared.awardLogo.fixed} className="w-full" />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-center w-full p-6 bg-white border-2 outline-none md:w-2/6 border-grey">
              <div className="flex justify-center w-full md:flex-1 md:px-2">
                <span className="block pb-2 mb-2 text-base text-center lg:text-left lg:pb-0 md:text-lg md:mb-0">Trusted by our many happy customers</span>
              </div>
              <div className="w-full md:w-auto md:px-2">
                <div className="w-56 mx-auto text-center">
                  <Reviews hideExtraText />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="mb-8">
        <div className="container">
          <div className="w-full mx-auto md:w-10/12">
            <div className="w-full mx-auto text-center md:w-9/12 lg:w-7/12">
              <h1>{ page.heroHeading }</h1>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="mb-16 lg:mb-24">
        <p className="px-12 mb-8 text-xl leading-tight text-center align-middle lg:text-2xl font-display md:px-24">Get in touch today on <a href="tel:01159598900" className="inline-block break-all transition duration-500 ease-in-out text-primary hover:text-primary-dark focus:text-primary-dark contactlink">0115 959 8900</a> or <span className="block mt-3 ml-2 align-middle sm:mt-0 sm:inline-block"><Link to="/contact/" className="text-base capitalize btn btn--outline">Get in touch</Link></span></p>
      </section> */}

      {/* <UspBand
        usps={[
          { 
            heading: "Dedicated Client Teams",
            blurb: "Your dedicated team is made up of specialists from all digital disciplines, ensuring a collaborative, cross-platform strategy."
          },{
            heading: "Nationwide Account Team",
            blurb: "We have Account Managers located across the country. They will visit you to learn about your business and help you achieve your goals online."
          }, {
            heading: "A Multi-Discipline Agency",
            blurb: "From web design and PPC to branding, photography and video, we’re a true full-service digital agency and have experts across all fields."
          }]}
      /> */}

      <section className="relative z-10 mb-16 overflow-hidden lg:mb-32 xl:my-20" id="about">
        <div className="container">
          <div className="flex flex-wrap lg:-mx-12 xl:items-center">
            <div className="w-full mb-5 lg:w-1/2 xl:w-5/12 lg:px-12 lg:mb-0">
              <div className="w-full content lg:pr-12 xl:pr-0" dangerouslySetInnerHTML={{ __html: page.content }}></div>
            </div>

            <div className="w-full md:w-1/2 md:px-8">
              { page.heroImage && (
                <div className="max-w-xs mx-auto mb-12 lg:max-w-md md:mb-0">
                  <Img fluid={page.heroImage.fluid} />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      { page.featuredCaseStudy && (
        <FeaturedCaseStudy
          title={`Related Project <strong>&middot;</strong> ${page.featuredCaseStudy.title}`}
          slug={page.featuredCaseStudy.slug}
          blurb={page.featuredCaseStudy.teaserBlurb}
          image={page.featuredCaseStudy.teaserIpadImage}
        />
      )}

      { page.meetYourAm.length ? (
      <section className="pt-12 overflow-hidden md:pt-20 xl:py-24 bg-secondary-dark">
        <div className="container">
          {page.meetYourAm.map(({content, profilePicture}, i) => {
            return (
              <div className="flex-no-wrap lg:-mx-8 md:flex">
                <div className="w-full mb-6 lg:w-4/12 lg:px-8 md:mb-12 lg:mb-0" key={i}>
                  <p className="block mb-3 text-white uppercase font-display text-md">Meet your local <br />Account Director</p>
                  <Img fluid={ profilePicture.fluid } className="w-10/12 lg:max-w-lg"/>
                </div>

                
                <div className="w-full mb-12 lg:w-8/12 lg:px-8 lg:mb-0">
                  <span className="block mb-6 leading-snug text-white local-am lg:mb-16 xl:mb-20" dangerouslySetInnerHTML={{ __html: content }}></span>
                </div>
              </div>
            )
          })}
        </div>
      </section>
      ) : (<></> )}
      
    
      {/* Watford Address Section */}
      { page.showWatfordLocation ? (
      <section className="mt-12">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              <div className="p-12 bg-primary xl:p-24">
                <span className="block h3">Watford Office</span>
                <div className="mb-6 text-xl text-white">
                  <span>Adtrak</span>
                  <div>
                    <span className="block">
                    The Junction, <br/>
                    Station Road, <br/>
                    </span>
                    <span className="block">Watford</span>
                    <span className="block">WD17 1ET</span>
                  </div>
                </div>

                <a href="tel:01159598900" className="block text-xl text-white transition duration-500 ease-in-out hover:text-secondary-dark focus:text-secondary-dark contactlink">01923 932 794</a>
                <a href="mailto:hello@adtrak.co.uk" className="block text-xl text-white transition duration-500 ease-in-out hover:text-secondary-dark focus:text-secondary-dark contactlink">hello@adtrak.co.uk</a>
              </div>
            </div>

            <div className="w-full lg:w-1/2">
              <iframe
                title="Adtrak Location Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4949.580903156694!2d-0.3972857!3d51.6636729!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48766b40beab1be5%3A0x1dc6b71797b73cb!2sAdtrak%20Watford!5e0!3m2!1sen!2suk!4v1662646096146!5m2!1sen!2suk"
                className="w-full h-64 lg:h-full"
                frameBorder="0"></iframe>
            </div>
          </div>
        </div>
      </section>
       ) : (<></> )}

      <FormAudit />

      <section className="relative pt-10 mb-12 overflow-hidden border-b md:pt-16 2xl:pt-24 md:mb-20 xl:mb-24 border-grey">
        <div className="container">
          <div className="w-full md:w-2/3 lg:w-8/12">
            <h2 className="h1">What We Do</h2>
            <p className="text-lg lg:text-xl">With over 22 years of experience, we’re one of Nottingham’s longest standing and most reputable digital marketing companies. As a full-service agency, we have a wealth of knowledge and technical expertise and a proven track record of delivering results. Our ultimate aim is to ensure your business generates quality leads through a strategic and high-impact digital solution.</p>
          </div>

          <div className="relative z-10 mb-10 mr-4 bg-transparent md:mr-8">
            <div className="flex flex-wrap pt-16 pb-10 pr-12 md:-mx-8 md:pb-0 md:pt-20 lg:pt-24">
              {page.ourServices.map(({ title, model, slug, treeParent, heroExcerpt, icon }, index) => {
                // Generate the top level slug from the models apiKey
                let slugged = slugify(model.apiKey + 's',{
                  lower: true,
                }).replace(/[_]/g, '-');
                let trimmedHeroExcerpt = heroExcerpt.replace(/^(.{120}[^\s]*).*/, "$1...");

                return (
                  <div key={index} className="flex flex-wrap w-full mb-8 md:w-1/2 lg:w-1/4 md:mb-16 md:px-8 lg:mb-20">
                    { icon && (
                      // @TODO convert this to SVG component?
                      <div className="w-full">
                        <svg width="35" height="35" className="block mb-8 lg:mb-10">
                          <image href={icon.url} src={icon.url} width="35" height="35"/>
                        </svg>
                      </div>
                    )}

                    <Link to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`} className="block w-full mb-4 h3">{ title }</Link>

                    { heroExcerpt && (
                      <div className="w-full mb-6 text-lg" dangerouslySetInnerHTML={{ __html: trimmedHeroExcerpt }}></div>
                    )}

                    <Link
                      // if it has a parent, pipe it into the link, if not leave it out 
                      to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`}
                      className="block w-full mt-auto text-lg link text-secondary font-display"
                    >Read more <span className={`ml-2 inline-block text-primary`}><Triangle size={`small`} /></span></Link>
                  </div>
                )
              })}
            </div>
            <div className="flex flex-wrap w-full pb-10 justi sm:-mx-2 md:pb-24 lg:pb-24 lg:mt-8">
              <div className="w-full mb-4 sm:w-auto sm:mb-0 sm:px-2">
                <Link to="/services/" className="block w-full btn btn--primary">Digital Services</Link>
              </div>
              <div className="w-full sm:w-auto sm:px-2">
                <Link to="/creative-services/" className="block w-full btn btn--primary">Creative Services</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Career

export const query = graphql`
  query locationPageQuery($slug: String!) {
    shared: datoCmsShared {
      awardLogo {
        fixed(width: 500, height: 500) {
          ...GatsbyDatoCmsFixed
        }
      }
    }
    page: datoCmsLocation(slug: { eq: $slug }) {
      metaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }
      title
      heroHeading
      heroBlurb
      content
      showWatfordLocation
      heroImage {
        fluid(
          maxWidth: 720
          imgixParams: {w: "720", fit: "crop", crop: "faces, edges"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      contentImage {
        fluid(
          maxWidth: 720
          imgixParams: {h: "720", w: "720", fit: "crop", crop: "faces, edges"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      miniCaseStudy {
        metaText
        heading
        image {
          fluid(
            maxWidth: 1200
            imgixParams: {h: "600", w: "1200", fit: "crop", crop: "center"}) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      extraCaseStudies {
        slug
        title
        teaserImage {
          fluid(
            maxWidth: 900
            imgixParams: {h: "900", w: "900", fit: "crop", crop: "center"}) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      meetYourAm {
        content
        profilePicture {
          fluid(
            maxWidth: 300
            maxHeight: 300
            imgixParams: {h: "300", w: "300", fit: "crop", crop: "faces, edges"}) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      featuredCaseStudy {
        title
        teaserBlurb
        teaserIpadImage {
          fluid(
            maxWidth: 900
            imgixParams: {h: "500", w: "900", fit: "crop", crop: "center"}) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
        slug
      }
      ourServices {
        ... on DatoCmsService {
          id
          slug
          title
          heroExcerpt
          icon {
            url
          }
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
        ... on DatoCmsCreativeService {
          id
          slug
          title
          heroExcerpt
          icon {
            url
          }
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
      }
    }
  }
`